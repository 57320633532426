import { ArrowRightIcon, Card, PageHead } from '@myob/myob-widgets';
import { Region } from '@my-account/tools';
import { auConfig, nzConfig } from 'config';
import { HelpLinkWrapper } from '../styles';
import { trackClickEventInDashboard } from 'helpers/telemetry';
import { HTMLAttributeAnchorTarget, useContext } from 'react';
import { checkSoloAccount } from '../../../helpers/query';
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account';
import { productFeatureFlag } from '../../../configs/FeatureFlags';

export const HelpLinks: React.FC<{ region: Region; clientId?: string }> = ({ region, clientId }) => {
  const config = region === 'NZ' ? nzConfig : auConfig;
  const { isSoloAccount } = useContext<AccountSelectContextValue>(AccountSelectContext);
  const isCommunicationPreferencesEnabled = productFeatureFlag.isCommunicationPreferencesEnabled();
  const links: Array<{ label: string; link: string; target?: HTMLAttributeAnchorTarget }> = [
    {
      label: 'Password and 2FA',
      link: config.PASSWORD_AND_TWO_FA,
    },
    {
      label: 'Change contact details',
      link: config.CHANGE_CONTACT_DETAILS,
    },
    {
      label: 'Communication preferences',
      link: isCommunicationPreferencesEnabled
        ? config.CHANGE_COMMUNICATION_PREFERENCES
        : config.COMMUNICATION_PREFERENCES,
    },
    {
      label: 'Help articles',
      link: config.HELP_ARTICLES,
      target: '_blank',
    },
  ];

  if (checkSoloAccount(isSoloAccount)) {
    links.splice(3, 1);
    if (!isCommunicationPreferencesEnabled) {
      links.splice(2, 1);
    }
  }

  return (
    <HelpLinkWrapper>
      <Card
        classes={['help-links-wrapper']}
        header={<Card.Header child={<PageHead title="Looking for something else?" />} />}
      >
        <ul className="help-links">
          {links.map((link) => (
            <li key={link.label}>
              <a
                className="help-link-item"
                href={link.link}
                onClick={() => trackClickEventInDashboard(link.label, link.link, clientId, true)}
                target={link.target}
                rel="noopener noreferrer"
              >
                <span>{link.label}</span>
                <ArrowRightIcon color="brand" className="help-link-item__icon" />
              </a>
            </li>
          ))}
        </ul>
      </Card>
    </HelpLinkWrapper>
  );
};
